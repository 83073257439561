<template>
  <router-view />
</template>

<script setup>
import { linkWallet } from "@/utils/wallet";
import { onMounted } from "vue";
onMounted(() => {
  linkWallet();
});
</script>

<style lang="scss">
.btn {
  padding: 15px;
  border-radius: 15px;
  border-radius: 1.25rem;
  background: linear-gradient(180deg, #004aad 0%, #cb6ce6 100%);
  color: var(--text-color);
  border: none;
  font-weight: 800;
  transition: 0.3s all;
  cursor: pointer;
  /* min-width: 150px; */

  &:hover {
    color: var(--text-gard-color);
    background: linear-gradient(180deg, #004bad77 0%, #cc6ce68e 100%);
  }
}

.btnT {
  padding: 15px;
  border-radius: 15px;
  border-radius: 1.25rem;
  background: #8448e8;
  color: var(--text-color);
  border: none;
  font-weight: 800;
  transition: 0.3s all;
  /* min-width: 150px; */

  &:hover {
    color: var(--text-gard-color);
    background: #8548e8af;
  }
}
</style>
