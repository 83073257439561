import store from "@/store";
import { ethers } from "ethers";
import erc20 from "./abi/erc20.json";
import nft from "./abi/nft.json";
import { ElNotification } from "element-plus";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();

const usdtAddress = process.env.VUE_APP_USDTADDRESS;
const ContractAddress = process.env.VUE_APP_CONTRANCtADDRESS;

const usdtContract = new ethers.Contract(usdtAddress || "", erc20, signer);
const Contract = new ethers.Contract(ContractAddress || "", nft, signer);
const linkWallet = async () => {
  if (window.ethereum) {
    const arr = await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [
        {
          chainId: 56, // 目标链ID
        },
      ],
    });
    console.log(arr[0]);
    store.commit("setUserAddress", arr[0]);
    return arr[0];
  } else {
    ElNotification.error({
      title: "Error",
      message: "Please install Metamask",
    });
    console.log("Please install Metamask");
  }
};

const changeUser = () => {
  // 监听账户切换事件
  window.ethereum.on("accountsChanged", async (accounts) => {
    if (accounts.length > 0) {
      // 执行账户切换后的逻辑
      store.commit("setUserAddress", accounts[0]);
      console.log("当前链接的账户地址是：" + accounts[0]);

      //accounts里的是钱包链接的所有账户地址
    } else {
      store.commit("setUserAddress", "");
      // 处理没有连接账户的情况
      console.log("没有账户链接");
    }
  });
};

const changeChainId = () => {
  window.ethereum.on("networkChanged", (chainId) => {
    console.log("当前链接的链ID是：" + chainId);
    if (chainId != "56") {
      ElNotification.info({
        title: "Info",
        message: "请切换到目标链！",
      });
    }
  });
};

// const watchBlock = () => {
//   const provider = new ethers.providers.Web3Provider((window as any).ethereum);
//   //监听区块变化触发
//   provider.on("block", async (blockNumber) => {
//     console.log("当前变化的区块号码是：" + blockNumber);
//     // 根据区块号获取交易信息获取
//     const block = await provider.getBlock(blockNumber);
//     // 获取区块中的交易列表
//     const transactions = block.transactions;
//     // 获取每笔交易的详细信息
//     const transactionPromises = transactions.map((txHash) =>
//       provider.getTransaction(txHash)
//     );
//     const transactionDetails = await Promise.all(transactionPromises);
//     // 打印交易信息
//     transactionDetails.forEach((tx) => {
//       console.log(tx);
//       console.log("Transaction Hash:", tx.hash);
//       console.log("From:", tx.from);
//       console.log("To:", tx.to);
//       console.log("Value:", ethers.utils.formatEther(tx.value));
//       console.log("--------------------------");
//     });
//   });
// };

const approve = async (amount) => {
  console.log(usdtContract, "usdtContract");

  console.log(ContractAddress, "nftAddress");
  if (store.state.userAddress) {
    console.log(store.state.userAddress, "userAddress");
    const allowance = await usdtContract.allowance(
      store.state.userAddress,
      ContractAddress
    );
    console.log(allowance, "allowance");

    if (allowance.lt(ethers.utils.parseEther(amount.toString()))) {
      try {
        await (
          await usdtContract.approve(
            ContractAddress,
            ethers.utils.parseEther(amount.toString())
          )
        ).wait();
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("已经授权");
      return;
    }
  } else {
    // linkWallet();
  }
};

export {
  linkWallet,
  changeUser,
  changeChainId,
  approve,
  usdtContract,
  Contract,
  usdtAddress,
  ContractAddress,
  signer,
};
