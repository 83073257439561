<template>
  <div class="main">
    <div class="left">
      <img src="@/assets/logo.png" alt="" />
      POLX
    </div>
   
    <div class="right">
      <div class="menu">
        <!-- <ul>
          <li>
            <router-link to="home">Home</router-link>
          </li>
          <li>
            <router-link to="token">Token</router-link>
          </li>
        </ul> -->
        <!-- <ul>
          <a href="https://x.com/polx_team" target="_blank">
            <li>
              <img
                src="https://framerusercontent.com/images/hg9RvD9ZcHzkwOpnNaLz7h6YqA.png"
                alt=""
              />
            </li>
          </a>
          <a href="https://t.me/Polysixtm" target="_blank">
            <li>
              <img
                src="https://framerusercontent.com/images/hY78mJdhhbKZQMdHZCcz0goT5s.png"
                alt=""
              />
            </li>
          </a>
          <a href="https://polx.gitbook.io/polx-1/" target="_blank">
            <li>
              <img
                style="height: 50px"
                src="@/assets/image/whiltbook.png"
                alt=""
              />
            </li>
          </a>
        </ul> -->
      </div>

      <div class="link">
        <button class="btn" @click="linkWallet">
          {{
            store.state.userAddress
              ? "0x..." + store.state.userAddress.slice(-4)
              : "CONNECT WALLET"
          }}
        </button>
      </div>
      <div class="minimenu" @click="drawer = true">
        <svg
          t="1702974854415"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="4407"
          id="mx_n_1702974854416"
          width="32"
          height="32"
        >
          <path
            d="M853.333333 213.333333l0 85.333334-682.666666 0L170.666667 213.333333z"
            fill="#999999"
            p-id="4408"
          ></path>
          <path
            d="M853.333333 469.333333l0 85.333334-682.666666 0L170.666667 469.333333z"
            fill="#999999"
            p-id="4409"
          ></path>
          <path
            d="M853.333333 725.333333l0 85.333334-682.666666 0L170.666667 725.333333z"
            fill="#999999"
            p-id="4410"
          ></path>
        </svg>
      </div>
    </div>
    <el-drawer
      v-model="drawer"
      title=""
      size="100%"
      :before-close="handleClose"
    >
      <ul class="minimenuul">
        <!-- <li @click="push('/home')">Home</li>
        <li @click="push('/token')">Token</li> -->
        <a href="https://x.com/polx_team" target="_blank">
          <li>Twitter</li>
        </a>
        <a href="https://t.me/Polysixtm" target="_blank">
          <li>Telegram</li>
        </a>
        <a href="https://polx.gitbook.io/polx-1/" target="_blank">
          <li>White Paper</li>
        </a>
      </ul>
    </el-drawer>
  </div>
</template>

<script setup>
import route from "@/router";
import store from "@/store";
import { ref } from "vue";
import { linkWallet } from "@/utils/wallet";

const drawer = ref(false);
const handleClose = (done) => {
  done();
};

const push = (path) => {
  drawer.value = false;
  route.push(path);
};
</script>

<style lang="scss" scoped>
.main {
  height: 75px;
  width: 100%;
  display: flex;
  padding: 0 150px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 1px 1px -1px var(--border-color); */
}

.link {
  display: flex;
  align-items: center;
}
.left {
  display: flex;
  align-items: center;
  height: 100%;
  img {
    height: 80%;
  }
}
.right {
  min-width: 600px;
  display: flex;
  display: flex;
  justify-content: flex-end;
  .minimenu {
    display: none;
  }
  .menu {
    width: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: var(--text-color);
      font-weight: 600;
      li {
        cursor: pointer;
        vertical-align: middle;
        :hover {
          color: var(--text-gard-color);
          transition: all 0.3s;
        }
        img {
          height: 60px;
        }
      }
      .active {
        font-weight: 700;
        color: var(--primary-color);
      }
      font-weight: 350;
    }
    margin-right: 40px;
  }
}
.minimenuul {
  li {
    height: 56px;
    font-weight: 17px;
    font-weight: 500;
    line-height: 56px;
    padding-left: 10px;
    box-shadow: 0 1px 0 0 #2b2b2b; /* 在下边添加细线边框 */
  }
}

@media screen and (max-width: 750px) {
  .main {
    height: 52.5px;
    padding: 0;
  }
  .left {
    img {
      height: 80%;
    }
  }

  .right {
    min-width: 115px;
    .minimenu {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 12px;
    }
    .menu {
      display: none;
    }
  }

  .btn {
    padding: 10px;
    border-radius: 10px;
  }
}
</style>
